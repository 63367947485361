// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-consultation-js": () => import("./../src/pages/consultation.js" /* webpackChunkName: "component---src-pages-consultation-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investments-js": () => import("./../src/pages/investments.js" /* webpackChunkName: "component---src-pages-investments-js" */),
  "component---src-pages-planning-js": () => import("./../src/pages/planning.js" /* webpackChunkName: "component---src-pages-planning-js" */),
  "component---src-pages-riskreview-js": () => import("./../src/pages/riskreview.js" /* webpackChunkName: "component---src-pages-riskreview-js" */)
}

